import _ from 'lodash'

export const required = (name) => (value) => {
  if (value || value === 0) return true
  return `${name} is een verplicht veld!`
}

export const minmax = (props) => (value) => {
  if (value === '' || value === undefined) {
    return true
  }
  const minimum = _.get(props, 'minimum', null)
  const maximum = _.get(props, 'maximum', null)
  const exclusiveMinimum = _.get(props, 'exclusiveMinimum', null)
  const exclusiveMaximum = _.get(props, 'exclusiveMaximum', null)

  const minTest = !_.isNil(minimum) ? parseFloat(value) >= parseFloat(minimum) : true
  const exclusiveMinimumTest = !_.isNil(exclusiveMinimum) ? parseFloat(value) > parseFloat(exclusiveMinimum) : true
  const maxTest = !_.isNil(maximum) ? parseFloat(value) <= parseFloat(maximum) : true
  const exclusiveMaximumTest = !_.isNil(exclusiveMaximum) ? parseFloat(value) > parseFloat(exclusiveMaximum) : true

  if (minTest && maxTest && exclusiveMinimumTest && exclusiveMaximumTest) return true

  // Make this message generic for all situation (exclusive min, min or not defined and exclusive max, max or not defined)
  let message = `${props.description} moet`
  let combineMsg = ''

  if (!_.isNil(minimum)) {
    message = `${message} groter zijn dan of gelijk aan ${minimum}`
    combineMsg = 'en '
  } else if (!_.isNil(exclusiveMinimum)) {
    message = `${message} groter zijn dan ${exclusiveMinimum}`
    combineMsg = 'en '
  }
  if (!_.isNil(maximum)) message = `${message} ${combineMsg}kleiner zijn dan of gelijk aan ${maximum}`
  else if (!_.isNil(exclusiveMaximum)) message = `${message} ${combineMsg}kleiner zijn dan ${exclusiveMaximum}`

  if (!minimum && !maximum && !exclusiveMinimum && !exclusiveMaximum) {
    message = 'Min en Max niet gedefinieerd.'
  }
  return message
}

export const type = (props) => value => {
  const type = _.get(props, 'type', '')
  // If no type is set, return always true
  if (type === '' || value === undefined || value === '') {
    return true
  }
  if (type === 'number') {
    if (typeof value === 'string' && (value.includes('E') || value.includes('e') || value.includes('.'))) {
      return true
    }
    let parsedValue = value.toString()
    parsedValue = parsedValue.replace(',', '.')
    if (!isNaN(parsedValue)) {
      value = parseFloat(parsedValue)
    } else {
      value = undefined
    }
  }

  // Perform the actual validation of the type
  const typeTest = (typeof value).toString() === type
  if (typeTest) {
    props.data = value
    return true
  } else {
    return `${props.title} moet type ${props.type} hebben`
  }
}
