<template>
  <v-card>
    <v-toolbar
      color="primary"
      flat
      class="toolbar"
    >
      <v-toolbar-title color="text">
        Immissietoets <span class="version">{{version}}</span>
      </v-toolbar-title>

      <v-spacer />

      <template v-slot:extension>
        <v-tabs
          bg-color="navbar"
          slider-color="text"
          class="not-to-print"
          grow
        >
          <v-tab max-width="160px" to="/berekening/immissietoets">
            Stoflozing
          </v-tab>
          <v-tab max-width="160px" v-if="showTemperatureTab" to="/berekening/temperatuurlozing">
            Temperatuurlozing
          </v-tab>
          <v-spacer/>
          <v-tab max-width="160px" to="/wijzigingen">
            Wijzigingen
          </v-tab>
          <v-tab max-width="160px" to="/informatie">
            Informatie
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  name: 'NavigationBar',
  computed: {
    version () {
      return `v${this.$store.state.version}`
    },
    showTemperatureTab () {
      return process.env.VUE_APP_SHOW_TEMPERATURE !== 'false'
    }
  }
}
</script>

<style lang="css" scoped >
.version {
  color: grey;
  font-size: small;
}
</style>
