import { createRouter, createWebHistory } from 'vue-router'
import CalculationView from '@/views/CalculationView.vue'
import InformationView from '@/views/InformationView.vue'
import ChangesView from '@/views/ChangesView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/berekening/immissietoets'
  },
  {
    // calculationType: immissietoets, temperatuurlozing
    path: '/berekening/:calculationType',
    name: 'CalculationView',
    component: CalculationView
  },
  {
    path: '/informatie',
    name: 'InformationView',
    component: InformationView
  },
  {
    path: '/wijzigingen',
    name: 'ChangesView',
    component: ChangesView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
