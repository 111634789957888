<template>
  <div class="results-immission">
    <v-tabs
      v-model="tab"
      bg-color="navbar"
      color="text"
      center-active
      show-arrows
      class="not-to-print"
      grow
    >
      <v-tab href="#tree">
        Beslisboom
      </v-tab>

      <v-tab href="#drinkwater">
        Drinkwater inname
      </v-tab>

      <v-tab v-if="graphs.length > 0" href="#graphs">
        Grafieken
      </v-tab>
      <v-tab v-if="logs.length > 0" href="#logs">
        Logs
      </v-tab>

      <v-tab href="#pdf">
        PDF
      </v-tab>
    </v-tabs>

    <v-window v-model="tab" class="tabs-items">
      <v-window-item value="tree" class="tree" eager>
        <div class="ma-3">
          <v-row no-gutters>
            <v-col md="12">
              <decision-tree :steps="results" class="to-print"/>
                <span v-if="yaConcentration" class="to-print">
                  Toetsafstand: {{ maxMixingZoneDistance }} m<br>
                  Concentratie op MKN toetsafstand: {{ yaConcentration }} μg/l<br>
                  Concentratie op MAC toetsafstand: {{ macConcentration }} μg/l<br>
                  De concentratie op KRW waterlichaamniveau is {{mDeltaConcentrationFullmixWfd}} μg/l,
                  gegeven een KRW debiet van {{wFlowKrwWaterbody}} m³/s.
                </span>
            </v-col>
          </v-row>
        </div>
      </v-window-item>
      <v-window-item value="drinkwater" eager>
        <div class="ma-3 to-print">
          <h2>
            Drinkwater concentraties bij innamepunten
          </h2>
          <v-row class="pa-3">
            <data-table :tableHeaders="tableHeaders" :tableItems="tableItems" :hideDefaultFooter="false"/>
          </v-row>
        </div>
      </v-window-item>
      <v-window-item v-if="graphs.length > 0" value="graphs" eager>
        <div class="ma-3">
          <h2>
            Grafische weergave pluim
          </h2>
          <v-row class="pa-3" v-for="graph in graphs" :key="graph">
            <div class="graph to-print" v-html="graph">
            </div>
          </v-row>
        </div>
      </v-window-item>
       <v-window-item v-if="logs.length > 0" value="logs" eager class="not-to-print">
        <div class="ma-3" style="display: flex; height: 800px; overflow: auto;">
          <pre>
            <p v-html="logs" />
          </pre>
        </div>
      </v-window-item>
      <v-window-item value="pdf" class="not-to-print">
        <div class="ma-3">
          <h2>
            Exporteer pagina naar pdf
          </h2>
          <v-row class="pa-3">
            <print-component />
          </v-row>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import DecisionTree from '@/components/DecisionTree'
import DataTable from '@/components/DataTable'
import PrintComponent from '@/components/PrintComponent'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
  props: {
    input: {
      type: Object
    },
    errorMessage: {
      type: String
    },
    map: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      results: [],
      tab: null,
      graphs: [],
      logs: '',
      graphReady: false,
      tableHeaders: [
        {
          text: 'Locatie',
          align: 'left',
          sortable: false,
          value: 'name',
          class: 'primary'
        },
        {
          text: 'Concentratie verhoging [μg/l]',
          style: 'font-color: blue',
          align: 'left',
          sortable: false,
          value: 'drinkwater_concentration',
          class: 'primary'
        },
        {
          text: 'Achtergrondconcentratie [μg/l]',
          style: 'font-color: blue',
          align: 'left',
          sortable: false,
          value: 'background_concentration',
          class: 'primary'
        },
        {
          text: 'Totale concentratie [μg/l]',
          style: 'font-color: blue',
          align: 'left',
          sortable: false,
          value: 'total_drinkwater_concentration',
          class: 'primary'
        },
        {
          text: 'Voldoet aan norm',
          style: 'font-color: blue',
          align: 'left',
          sortable: false,
          value: 'complies_with_norm',
          class: 'primary'
        }
      ],
      tableItems: [],
      maxMixingZoneDistance: null,
      yaConcentration: null,
      macConcentration: null,
      mDeltaConcentrationFullmixWfd: null, // TODO: krw is refered as wfd and krw. Check for consistency
      wFlowKrwWaterbody: null,
      graphImage: '',
      treeImage: {},
      DecisionTree
    }
  },
  watch: {
    input () {
      this.getCalculation()
      this.getIntakeCalculation()
    }
  },
  components: {
    DecisionTree,
    DataTable,
    PrintComponent
  },
  mounted () {
    this.getCalculation()
    this.getIntakeCalculation()
  },
  computed: {
    ...mapGetters(['intakeInput']),
    drinkwaterTableHeader () {
      return this.tableHeaders.map(item => {
        return item.text
      })
    },
    drinkwaterTable () {
      return this.tableItems.map(item => {
        const tableRow = []
        this.tableHeaders.forEach(header => {
          tableRow.push(item[header.value])
        })
        return tableRow
      })
    }
  },
  methods: {
    getCalculation () {
      this.graphReady = false
      this.$emit('set-error-message', '')
      // Fetch simple or advanced calculation
      let endpoint = 'calculate'
      if (_.get(this.input, 'effluent')) {
        endpoint = 'calculate_advanced'
      }
      fetch(`${process.env.VUE_APP_SERVER_URL}/${endpoint}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.input)
      })
        .then(res => {
          // If the status code is not 200, return error message
          if (res.status === 400) {
            this.$emit('set-error-message', 'Er is een fout in de invoervelden.')
          }
          return res.json()
        })
        .then(response => {
          if (_.get(response, 'message_nl')) {
            this.$emit('set-error-message', _.get(response, 'message_nl'))
            return
          }
          // Check if the response contains either a result or an error message
          if (!response.detail) {
            this.$emit('update:correctResponseTime', `Laatste correcte berekening om: ${moment().format('HH:mm:ss DD-MM-YYYY')}`)
            if (_.get(response, 'graphs')) {
              this.results = response.details
              this.updateConcentrations()
              this.graphs = response.graphs
              this.maxMixingZoneDistance = response.m_mixingzone_ya_length
              this.mDeltaConcentrationFullmixWfd = response.m_deltaconc_wfd
              this.wFlowKrwWaterbody = response.w_flow_krw_waterbody
            } else {
              this.results = response
              this.graphs = []
            }
            if (_.get(response, 'logs')) {
              this.logs = response.logs
            }
            this.graphReady = true
          } else {
            let message = 'De berekening kon niet worden uitgevoerd en gaf de volgende melding: \n'
            response.detail.forEach(det => {
              message = `${message}${det.msg}\n`
            })
            this.$emit('set-error-message', message)
          }
        })
        .catch(() => {
          this.$emit('set-error-message', 'De berekening kon niet worden uitgevoerd en gaf een error.')
        })
    },

    getIntakeCalculation () {
      const inputs = {}
      // Map the defaults of the CalcInputConcentration to the input for the
      // calculate_drinkwater_concentration endpoint
      Object.entries(this.intakeInput).forEach(([key, value]) => {
        inputs[key] = value.data
      })
      fetch(`${process.env.VUE_APP_SERVER_URL}/calculate_drinkwater_concentration/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(inputs)
      })
        .then(res => {
          return res.json()
        })
        .then(response => {
          response.forEach(val => {
            val.complies_with_norm = val.complies_with_norm ? 'Ja' : 'Nee'
          })
          this.tableItems = response
        })
    },
    updateConcentrations () {
      // Find the step in de advanced calculation where the final_concentration_mac_value is calculated
      // and use this to display in the textfields.
      const result = this.results.find(result => result.final_concentration_mac_value !== null)
      this.macConcentration = _.get(result, 'final_concentration_mac_value', null)
      this.yaConcentration = _.get(result, 'final_concentration_ya_value', null)
    }
  }
}
</script>

<style scoped>
.tabs-items {
  height: auto;
}

@media print {
  .jqx-tabs-content {
      height: auto !important;
  }

  .v-window-item {
      display: block !important;
      height: auto !important;
  }
}

.graph {
  height: 400px;
}
</style>
