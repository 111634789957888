<template>
  <v-card class="pa-3 ma-3" outlined>
    <h2>
      Wijzigingen
    </h2>
    <div class="changes" md="12">
      <div class="changes-markdown" v-html="content" />
    </div>
  </v-card>
</template>

<script>
import content from '@/assets/content/changes.md'
export default {
  name: 'ChangesView',
  data () {
    return {
      content
    }
  }
}
</script>

<style scoped>
.information {
  width: 100%;
}
</style>
