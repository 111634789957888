<template>
  <div class="input-fields to-print">
    <h2 class="pb-3">
      Start {{$route.params.calculationType}}
    </h2>
    <h4 class="pb-3" ><i>
      Let op: binnenkort wordt een deel van de hydrologische gegevens geactualiseerd in het stroomgebied van de Rijn, Waal en Maas. Dit kan gevolgen hebben voor de resultaten na 1 juni 2024. Wanneer deze actualisatie toegepast is, is dat herkenbaar aan het versienummer 2.0.x.
      <p></p></i>
    </h4>
    <v-form ref="calculateForm" >
      <v-row>
        <v-col cols="1">
          <v-tooltip left max-width="500px" text="Klik met de linker muisknop op de kaart om een locatie voor de lozing te selecteren.">
            <template v-slot:activator="{ props }">
              <img :src="MapboxMarker" alt="my-logo" class="m-auto svg-image" v-bind="props" />
            </template>
          </v-tooltip>
        </v-col>
        <v-text-field
          v-if="!$attrs.location.lat"
          class="latlon-text px-3 py-3"
          variant="underlined"
          density="compact"
          solo
          hide-details
          readonly
          value="Klik op de kaart om een locatie te kiezen."
        />
        <v-text-field
          v-if="$attrs.location.lat"
          density="compact"
          readonly
          label="Latitude:"
          class="latitude px-3"
          variant="underlined"
          active
          :value="$attrs.location.lat.toFixed(4)"
        ></v-text-field>
        <v-text-field
          v-if="$attrs.location.lng"
          density="compact"
          readonly
          label="Longitude:"
          class="longitude px-3"
          variant="underlined"
          active
          :value="$attrs.location.lng.toFixed(4)"
        ></v-text-field>
      </v-row>
      <v-row v-if="$attrs.location.lat && !customLocation">
        <v-col cols="1">
          <v-tooltip max-width="500px" left text="Geselecteerd polygoon van de interactieve laag: 'locaties'">
            <template v-slot:activator="{ props }">
              <img :src="LocationsSelection" class="m-auto svg-image" v-bind="props" />
            </template>
          </v-tooltip>
        </v-col>
        <v-combobox
          class="px-3"
          :items="$store.state.locationsList"
          item-value="location_id"
          item-title="description"
          label="Locatie:"
          placeholder="Selecteer locatie ID"
          v-model="selectedLocation"
          density="compact"
          variant="underlined"
          :suffix="$store.state.locationDetails.description"
          :return-object="false"
        ></v-combobox>
      </v-row>
      <v-row v-if="$attrs.location.lng">
        <v-col cols="1">
          <v-tooltip max-width="500px" left text="Geselecteerd polygoon van de informatieve laag: 'drinkwater intake'">
            <template v-slot:activator="{ props }">
              <img :src="IntakeSelection" alt="my-logo" class="m-auto svg-image" v-bind="props" />
            </template>
          </v-tooltip>
        </v-col>
        <v-text-field
          class="px-3 pb-3"
          label="Dichtstbijzijnde lijn segment:"
          v-model="intakeInput.segment_id.data"
          density="compact"
          readonly
          variant="underlined"
          :return-object="false"
        ></v-text-field>
      </v-row>
      <v-text-field
        density="compact"
        readonly
        v-if="$attrs.location.lng && !customLocation"
        v-model="inputFields.watertype.text"
        :label="inputFields.watertype.description"
        ref="watertype"
        variant="underlined"
      ></v-text-field>
      <v-select
        v-if="customLocation"
        label="Selecteer een watertype"
        :items="watertypeSelectionItems"
        item-title="locationtype"
        item-value="locationtype"
        return-object
        @update:modelValue="selectCustomLocation"
        variant="underlined"
        >
        <!-- This select is set up from a list of locationtypes. This list
        contains 8 dummy locations, corresponding with the 8choices of watertypes
        you have. The user will see this select box whenever he/she clicks outside of the
        segmentstructure. He needs to choose a watertype for the custom location selected.
        So the select shows a watertype to select, but in reality the user selects one
        of the (BS) dummy locations we created in the database. These locations do not
        have a geometry. -->
        <v-tooltip text="Tooltip">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props">mdi-information-outline</v-icon>
          </template>
          <!-- eslint-disable-next-line max-len -->
          <span>watertype bepaalt de berekenwijze (NB KRW-type bij temperatuurlozing wordt enkel gebruikt in de beslisboom)</span>
        </v-tooltip>
      </v-select>
        <h4
          class="pb-3"
          v-if="normTypes[0].norms && $route.params.calculationType == 'immissietoets'">
          Stof en bijbehorende normen
        </h4>
        <v-autocomplete
          v-if="$attrs.location.lat && $route.params.calculationType == 'immissietoets' && inputFields.watertype.text"
          v-model="substance"
          density="compact"
          auto-select-first
          label="Kies een stof (zoek op CAS-nummer of naam):"
          item-title="description_nl"
          :items="substances"
          return-object
          :filter="searchSubstanceCAS"
          @change="changeSubstance"
          :loading="!normTypes[0].loaded"
          variant="underlined"
          :menu-props="{ 'max-width': '100px'}"
        >
          <template v-slot:loader>
            <v-progress-linear
              :active="$attrs.location.lat && !normTypes[0].loaded"
              indeterminate
              color="primary"
              absolute
            ></v-progress-linear>
          </template>
        </v-autocomplete>

        <v-row v-if="normTypes[0].norms && $route.params.calculationType == 'immissietoets'" dense>
          <v-col cols=3>
              <v-text-field
                density="compact"
                label="JG-MKN"
                :suffix="inputFields.e_ya.properties.unit"
                v-model="inputFields.e_ya.data"
                :bg-color="inputFields.e_ya.data !== inputFields.e_ya.default &&
                  inputFields.e_ya.default ? 'navbar' : undefined"
                :append-icon="inputFields.e_ya.data !== inputFields.e_ya.default &&
                  inputFields.e_ya.default ? 'mdi-refresh' : undefined"
                @click:append="inputFields.e_ya.data = inputFields.e_ya.default;
                  normTypes[0].selectedNorm = normTypes[0].norms[0];
                  updateInputsStore('e_ya', 'effluent', inputFields.e_ya.default)"
                @input="normTypes[0].selectedNorm = normTypes[0].norms[normTypes[0].norms.length -1]"
                variant="underlined"
                :rules="[required('JG-MKN'), type(inputFields.e_ya), minmax(inputFields.e_ya)]"
              >
              </v-text-field>
          </v-col>
          <v-col cols=9>
            <v-select
              density="compact"
              :items="normTypes[0].norms"
              :item-title="(norm) =>  `${norm.norm_description} ${norm.value ? '(' + norm.value + ')' : ''}`"
              item-value="norm_description"
              return-object
              v-model="normTypes[0].selectedNorm"
              :bg-color="inputFields.e_ya.data !== inputFields.e_ya.default &&
                inputFields.e_ya.default ? 'navbar' : undefined"
              :append-icon="inputFields.e_ya.data !== inputFields.e_ya.default &&
                inputFields.e_ya.default ? 'mdi-refresh' : undefined"
              @click:append="inputFields.e_ya.data = inputFields.e_ya.default;
                normTypes[0].selectedNorm = normTypes[0].norms[0]"
              @update:modelValue="norm => inputFields.e_ya.data = norm.value"
              variant="underlined"
            >
            <v-tooltip max-width="500px" left text="Maak gebruik van wetenschappelijke notatie (1.2E03 i.p.v. 1200) om rekening te houden met de meetnauwkeurigheid t.b.v. de KRW-toets">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">mdi-information-outline</v-icon>
              </template>
            </v-tooltip>
            </v-select>
          </v-col>
        </v-row>

        <!-- Discharge  -->
        <v-row dense>
          <v-col cols=6>
              <v-text-field
                v-if="normTypes[0].norms"
                density="compact"
                :label="dischargeName"
                v-model="inputFields.e_discharge_effluent.data"
                :suffix="inputFields.e_discharge_effluent.properties.unit"
                variant="underlined"
                @update:modelValue="intakeInput.e_discharge_effluent.data = inputFields.e_discharge_effluent.data"
                :rules="[required(dischargeName), type(inputFields.e_discharge_effluent), minmax(inputFields.e_discharge_effluent)]"
              >
              <template v-slot:append>
                <v-tooltip
                  v-if="inputFields.e_discharge_effluent.properties.info_nl"
                  left
                  :text="inputFields.e_discharge_effluent.properties.info_nl"
                  max-width="500px"
                  >
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-information-outline</v-icon>
                  </template>
                </v-tooltip>
              </template>
              </v-text-field>
          </v-col>
          <v-col cols=6>
              <v-text-field
                v-if="normTypes[0].norms"
                density="compact"
                :label="dischargeConcentrationName"
                v-model="inputFields.e_conc_effluent.data"
                :suffix="inputFields.e_conc_effluent.properties.unit"
                variant="underlined"
                @update:modelValue="intakeInput.e_conc_effluent.data = inputFields.e_conc_effluent.data"
                :rules="[required(dischargeConcentrationName), type(inputFields.e_conc_effluent), minmax(inputFields.e_conc_effluent)]"
              >
              <template v-slot:append>
                <v-tooltip max-width="500px" v-if="inputFields.e_conc_effluent.properties.info_nl" left :text="inputFields.e_conc_effluent.properties.info_nl">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <h4 class="pb-3" v-if="normTypes[0].norms">Meetpunt met achtergrondconcentraties</h4>
        <v-row v-if="normTypes[0].norms" dense>
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <img :src="MeasurementsSelection" alt="my-logo" class="mx-3" v-bind="props" />
            </template>
            <span>Geselecteerd polygoon van de informatieve laag: 'meetpunten'</span>
          </v-tooltip>
          <v-autocomplete
            class="pr-3"
            v-model="selectedMeasurement"
            density="compact"
            label="Meetpunt:"
            placeholder="Selecteer meetpunt ID"
            :items="measurementList"
            :loading="!backgroundLoaded"
            variant="underlined"
          >
            <template v-slot:loader>
              <v-progress-linear
                :active="!backgroundLoaded"
                indeterminate
                color="primary"
                absolute
              ></v-progress-linear>
            </template>
          </v-autocomplete>
          <v-col cols="6">
            <v-text-field
              v-show="normTypes[0].norms"
              density="compact"
              :label="inputFields.w_conc_background ? inputFields.w_conc_background.description : undefined"
              v-model="inputFields.w_conc_background.data"
              :suffix="inputFields.w_conc_background.properties.unit"
              class="px-3"
              ref="w_conc_background"
              :append-icon="inputFields.w_conc_background.data !== inputFields.w_conc_background.default &&
                inputFields.w_conc_background.default ? 'mdi-refresh' : undefined"
              @click:append="inputFields.w_conc_background.data = inputFields.w_conc_background.default;
                updateInputsStore('w_conc_background', 'water', inputFields.w_conc_background.default)"
              @update:modelValue="intakeInput.w_conc_background.data = inputFields.w_conc_background.data"
              :loading="!backgroundLoaded"
              variant="underlined"
              :rules="[required(inputFields.w_conc_background ? inputFields.w_conc_background.description : undefined), type(inputFields.w_conc_background), minmax(inputFields.w_conc_background)]"
            >
              <template v-slot:loader>
                <v-progress-linear
                  :active="!backgroundLoaded"
                  indeterminate
                  color="primary"
                  absolute
                ></v-progress-linear>
              </template>
              <template v-slot:append>
                <v-tooltip max-width="500px" v-if="inputFields.w_conc_background.properties.info_nl" left :text="inputFields.w_conc_background.properties.info_nl">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-information-outline</v-icon>
                  </template>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <h4 class="pb-3" v-if="normTypes[0].norms">Waterlichaam om in te lozen</h4>
        <v-row v-if="normTypes[0].norms">
          <v-col cols="1">
            <v-tooltip max-width="500px" left text="Geselecteerd polygoon van de informatieve laag: 'waterlichamen'">
              <template v-slot:activator="{ props }">
                <img :src="WaterbodySelection" alt="my-logo" class="m-auto svg-image" v-bind="props" />
              </template>
            </v-tooltip>
          </v-col>
          <v-col v-col="5">
            <v-combobox
              class="px-3"
              :items="$store.state.waterbodyList"
              label="KRW waterlichaam:"
              v-model="selectedWaterBody"
              item-value="krw_waterbodies_id"
              item-title="owm_naam"
              return-object
              density="compact"
              :bg-color="checkKRWupdate()"
              :append-icon="refreshSelectedWaterbody"
              @click:append="$emit('update:selectedWaterBody', $attrs.clickedWaterbody); $emit('changed-input')"
              @input="$emit('changed-input')"
              variant="underlined"
            ></v-combobox>
          </v-col>
          <v-col cols="6">
            <v-text-field
              density="compact"
              variant="underlined"
              :label="intakeInput.w_drinking_water_standard_surfacewater.description"
              :suffix="intakeInput.w_drinking_water_standard_surfacewater.properties.unit"
              :append-icon="intakeInput.w_drinking_water_standard_surfacewater.data !==
                intakeInput.w_drinking_water_standard_surfacewater.default &&
                intakeInput.w_drinking_water_standard_surfacewater.default ? 'mdi-refresh' : undefined"
              @click:append="intakeInput.w_drinking_water_standard_surfacewater.data =
                intakeInput.w_drinking_water_standard_surfacewater.default"
              v-model="intakeInput.w_drinking_water_standard_surfacewater.data"
              class="px-3"
              ref="w_drinking_water_standard_surfacewater"
              :rules="[required(intakeInput.w_drinking_water_standard_surfacewater ? intakeInput.w_drinking_water_standard_surfacewater.description : undefined), type(intakeInput.w_drinking_water_standard_surfacewater)]"
            ></v-text-field>
          </v-col>
        </v-row>

    </v-form>
    <v-btn v-if="normTypes[0].norms" @click="sendInputFields">Resultaten</v-btn>
    <v-alert v-if="calculationStatus === 'succes'" type="info" density="compact" variant="outlined" class="mt-3 mb-0">
      De berekening wordt uitgevoerd met de ingevulde velden.
    </v-alert>
    <v-alert v-if="calculationStatus === 'error'" type="error" density="compact" variant="outlined" class="mt-3 mb-0">
      Er missen nog gegevens in de bovenstaande velden en de geavanceerde berekening kon nog niet worden uitgevoerd.
    </v-alert>
    <v-alert v-if="calculationStatus === 'errorMessage'" type="error" density="compact" variant="outlined" class="mt-3 mb-0">
      {{ errorMessage }}
    </v-alert>
  </div>
</template>

<script>
import MapboxMarker from '@/assets/mapbox-marker.svg'
import LocationsSelection from '@/assets/locations-selection.svg'
import WaterbodySelection from '@/assets/waterbody-selection.svg'
import MeasurementsSelection from '@/assets/measurements-selection.svg'
import IntakeSelection from '@/assets/intake-selection.svg'
import _ from 'lodash'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { required, minmax, type } from '@/utils/rules'

export default {
  data () {
    return {
      substances: [],
      substance: '',
      droppingDischarge: '',
      droppingConcentration: '',
      watertypes: [],
      locationData: [],
      formValid: false,
      calculationStatus: null, // 'succes', 'error', 'errorMessage'
      backgroundLoaded: true,
      normTypes: [{
        normtype: 'JG-MKN',
        norms: null,
        selectedNorm: {
          norm_description: ''
        },
        loaded: true,
        parameterObject: 'inputFields',
        parameter: 'e_ya'
      }, {
        normtype: 'drinkwater',
        norms: null,
        selectedNorm: {
          norm_description: ''
        },
        loaded: true,
        parameterObject: 'intakeInput',
        parameter: 'w_drinking_water_standard_surfacewater'
      }],
      // Rules for validation:
      required,
      minmax,
      type,
      // Svg image loads:
      MapboxMarker,
      LocationsSelection,
      WaterbodySelection,
      MeasurementsSelection,
      IntakeSelection
    }
  },
  mounted () {
    this.loadWatertypeSelectionItems()
    this.getSubstances()
    this.getWatertypes()
    this.getLocationData()
  },
  watch: {
    '$route.params.calculationType' () {
      this.normTypes.forEach(nt => {
        nt.norms = null
        nt.selectedNorm = {
          norm_description: ''
        }
      })
      this.substance = ''
    },
    selectedMeasurement () {
      this.getBackgroundData()
    },
    selectedWaterBody () {
      this.getWaterbodyData()
    },
    selectedLocation (val) {
      this.getLocationData()
    },
    errorMessage () {
      if (this.errorMessage !== '') {
        this.calculationStatus = 'errorMessage'
      }
    },
    substance () {
      this.changeSubstance()
    }
  },
  computed: {
    ...mapGetters(['calcInput', 'intakeInput', 'watertypeSelectionItems', 'customLocation']),
    measurementList () {
      return this.$store.state.measurementsList.map(meas => meas.background_id)
    },
    selectedWaterBody: {
      get () {
        const waterbody = this.$store.state.waterbodyList.find(body => body.krw_waterbodies_id === this.$attrs.selectedWaterBody)
        return waterbody
      },
      set (val) {
        this.$emit('update:selectedWaterBody', val.krw_waterbodies_id)
      }
    },
    selectedLocation: {
      get () {
        return this.$attrs.selectedLocation
      },
      set (val) {
        this.$emit('update:selectedLocation', val)
      }
    },
    selectedMeasurement: {
      get () {
        return this.$attrs.selectedMeasurement
      },
      set (val) {
        this.$emit('update:selectedMeasurement', val)
      }
    },
    inputFields: {
      get () {
        return this.calcInput
      },
      set (val) {
        this.setCalcInput(val)
      }
    },
    locationText () {
      if (Object.keys(this.$attrs.location).length === 0) {
        return 'Klik op de kaart om een locatie te kiezen.'
      } else {
        return `Latitude: ${this.$attrs.location.lat.toFixed(4)}; Longitude: ${this.$attrs.location.lng.toFixed(4)}`
      }
    },
    dischargeName () {
      return _.get(this.inputFields.e_discharge_effluent, 'description', '')
    },
    dischargeConcentrationName () {
      return _.get(this.inputFields.e_conc_effluent, 'description', '')
    },
    refreshSelectedWaterbody () {
      const wbId = _.get(this.selectedWaterBody, 'krw_waterbodies_id')
      if (wbId) {
        return wbId !== this.$attrs.clickedWaterbody ? 'mdi-refresh' : undefined
      } else {
        return undefined
      }
    }
  },
  methods: {
    ...mapActions(['loadBackgroundPoints', 'loadBackgroundList', 'loadLocationsList',
      'loadWaterbody', 'loadWatertypeSelectionItems']),
    ...mapMutations(['setCalcInput', 'setLocationDetails', 'addAdvancedCalcInput']),
    getSubstances () {
      // get the entire list of substances
      fetch(`${process.env.VUE_APP_SERVER_URL}/substances/`)
        .then(res => {
          return res.json()
        })
        .then(response => {
          this.substances = response
        })
    },
    getWatertypes () {
      // get the entire list of substances
      fetch(`${process.env.VUE_APP_SERVER_URL}/watertypes/`)
        .then(res => {
          return res.json()
        })
        .then(response => {
          this.watertypes = response
        })
    },
    getLocationData () {
      // get the norms that belong to the selected substance
      if (this.selectedLocation === '') {
        // don't search for a location if empty
        return
      }
      fetch(`${process.env.VUE_APP_SERVER_URL}/locations/${this.selectedLocation}`)
        .then(res => {
          return res.json()
        })
        .then(response => {
          // TODO: only send these details to the store (or move this funtion to store)
          // Should be generic way to set the CalcInput/AdvancedCalcInput fields from
          // data we gathered from the db.
          this.locationData = response
          const newInput = this.inputFields

          // Check if watertype has changed and update input fields accordingly
          const watertype = this.watertypes.find(type => type.watertype_id === response.watertype_id)
          newInput.watertype.data = response.watertype_id
          newInput.watertype.text = _.get(watertype, 'description_nl', '')

          // When watertype is updated, also update substance norms again
          if (this.normTypes[0].norms) {
            this.changeSubstance()
          }

          // For all inputfields that have source = 'locations', update default value
          Object.entries(newInput).forEach(input => {
            if (_.get(input[1], 'properties.nwm_available') &&
              _.get(input[1], 'properties.nwm_available.watertypes').includes(watertype)) {
              return
            }
            const source = _.get(input[1], 'properties.source')
            const locValue = _.get(this.locationData, input[0])

            const nwm = _.get(input[1], 'properties.nwm_available')
            if (source === 'locations' && !(nwm && _.get(nwm, 'watertypes', []).includes(watertype))) {
              input[1].data = locValue
              input[1].default = locValue
            }
          })
          this.inputFields = newInput
          this.setLocationDetails(response)
        })
    },
    changeSubstance () {
      // Reset substance and selectedmeasurement location when new substance is chosen
      this.$emit('set-substance', this.substance)
      this.selectedMeasurement = ''
      const subId = _.get(this.substance, 'substance_id')
      const waterId = _.get(this.inputFields, 'watertype.data')
      if (!(subId && waterId)) {
        return
      }

      this.normTypes.forEach(nt => {
        // Show loading icon in the meantime
        nt.loaded = false
        const url = process.env.VUE_APP_SERVER_URL
        // Update substance norms for JG-MKN with new normtype, watertype_id and substance_id
        fetch(`${url}/substance_norms/?substance_id=${subId}&normtype=${nt.normtype}&watertype_id=${waterId}`)
          .then(res => {
            return res.json()
          })
          .then(response => {
            // if there are no norms available, leave empty field
            let value = null
            if (nt.normtype === 'drinkwater') {
              value = 1
            }
            const manual = {
              norm_description: 'Handmatige invoer',
              value,
              unit: 'μg/l'
            }
            if (response.length !== 0) {
              nt.norms = response.map(norm => {
                const val = _.get(norm, 'value')
                if (val) {
                  if (val.includes('E') || val.includes('e')) {
                    norm.value = val
                  } else {
                    norm.value = parseFloat(val.replace(/,/, '.'))
                  }
                }
                return norm
              })
              nt.norms.push(manual)
            } else {
              nt.norms = [manual]
            }
            nt.selectedNorm = nt.norms[0]
            this[nt.parameterObject][nt.parameter].data = _.get(nt.norms[0], 'value')
            this[nt.parameterObject][nt.parameter].default = _.get(nt.norms[0], 'value')
            this[nt.parameterObject][nt.parameter].userInput = false
            nt.loaded = true
            this.addAdvancedCalcInput({
              param: nt.parameter,
              group: 'effluent',
              props: {
                data: _.get(nt.norms[0], 'value'),
                default: _.get(nt.norms[0], 'value'),
                userInput: false
              }
            })
          })
      })

      // Update substance norms MAC-MKN with new normtype, watertype_id and substance_id
      fetch(`${process.env.VUE_APP_SERVER_URL}/substance_norms/?substance_id=${subId}&normtype=MAC-MKN&watertype_id=${waterId}`)
        .then(res => {
          return res.json()
        })
        .then(response => {
          const def = _.get(response, '[0].value', null)
          this.addAdvancedCalcInput({
            param: 'e_mac',
            group: 'effluent',
            props: {
              data: typeof def === 'string' ? parseFloat(def.replace(',', '.')) : def,
              default: typeof def === 'string' ? parseFloat(def.replace(',', '.')) : def,
              items: [...response, {
                norm_description: 'Handmatige invoer',
                value: null,
                unit: 'μg/l'
              }],
              selectedItem: response[0]
            }
          })
        })

      // Update geojson and list with all available meaurement point according
      // to this nee substance
      this.loadBackgroundPoints(this.substance.substance_id)
      this.loadBackgroundList(this.substance.substance_id)
    },
    getBackgroundData () {
      this.backgroundLoaded = false

      // Get the data through the background id
      fetch(`${process.env.VUE_APP_SERVER_URL}/backgrounds/${this.selectedMeasurement}`)
        .then(res => {
          return res.json()
        })
        .then(response => {
          const paramName = 'w_conc_background'
          this.inputFields[paramName].default = response.value
          this.inputFields[paramName].data = response.value
          this.intakeInput.w_conc_background.data = response.value
          this.inputFields.userInput = false
          this.backgroundLoaded = true
          this.addAdvancedCalcInput({
            param: paramName,
            group: 'water',
            props: {
              data: response.value,
              default: response.value,
              userInput: false
            }
          })
        })
    },
    getWaterbodyData () {
      // Get the data through the background id
      this.loadWaterbody(_.get(this.selectedWaterBody, 'krw_waterbodies_id'))
    },
    sendInputFields () {
      // Only send inputfields if validation is true, otherwise show which
      // fields still need a proper value
      console.log(this.$refs.calculateForm)
      this.$refs.calculateForm.validate()
      if (this.$refs.calculateForm.isValid) {
        this.calculationStatus = 'succes'
        const inputs = {}
        Object.entries(this.inputFields).forEach(([key, value]) => {
          const inputValue = value.data === '' ? null : value.data
          inputs[key] = inputValue
        })
        this.intakeInput.e_conc_effluent.data = this.inputFields.e_conc_effluent.data
        this.intakeInput.e_discharge_effluent.data = this.inputFields.e_discharge_effluent.data
        this.intakeInput.substance_id.data = _.get(this.substance, 'substance_id')
        this.intakeInput.w_conc_background.data = this.inputFields.w_conc_background.data
        // Sometimes w_flow or w_width coming from the DB is null. replace with default (=0.0)
        // Especially happens with coastal areas
        // Leave this as default and not data according to the above comment
        inputs.w_flow = inputs.w_flow || this.inputFields.w_flow.default
        inputs.w_width = inputs.w_width || this.inputFields.w_width.default
        this.$emit('input-completed', inputs)
      } else {
        this.calculationStatus = 'error'
      }
    },
    updateInputsStore (param, group, data) {
      this.$emit('changed-input')
      // TODO: for some reason the store won't detect an update for only the e_ya
      // parameter. This is necessary: update both the calcInput and the advancedCalcinput
      // manually in the store..
      _.set(this.calcInput, `${param}.data`, data)
      this.setCalcInput(this.inputFields)
      this.addAdvancedCalcInput({ param, group, props: { data, userInput: true } })
    },
    selectCustomLocation (location) {
      this.selectedLocation = location.location_id
      this.inputFields.watertype.text = location.watertype
    },
    searchSubstanceCAS (item, queryText, itemText) {
      const data = `${itemText}${item.casnummer}`.toLowerCase()
      const searchText = queryText.toLowerCase()
      return data.includes(searchText)
    },
    checkKRWupdate () {
      let color = 'navbar'
      if (this.$attrs.selectedWaterBody === this.$attrs.clickedWaterbody) {
        color = undefined
      }
      return color
    }
  }
}
</script>

<style lang="css">
  .v-text-field__suffix {
    font-size: 90%;
    color: rgba(0,0,0,.6);
  }

  .svg-image {
    height: min-content;
  }
</style>
