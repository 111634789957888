<template>
  <div />
</template>

<script>
export default {
  name: 'VMapboxBackgroundLayers',
  props: {
    layers: {
      type: Array,
      required: true
    }
  },
  watch: {
    layers: {
      handler () {
        this.toggleVisibleRaster()
      },
      deep: true
    }
  },
  data () {
    return {
      map: undefined
    }
  },
  mounted () {
    this.map = this.$parent.map
    this.toggleVisibleRaster()
  },
  methods: {
    toggleVisibleRaster () {
      // Toggle the visibility of the layers in the layer list. When the layer
      // is not present on the map add to the map.
      if (!this.map) {
        return
      }
      this.layers.forEach(layer => {
        if (layer.id === 'no-data') {
          return
        }

        const layerPresent = this.map.getLayer(layer.id)
        if (layerPresent && !layer.active) {
          this.map.removeLayer(layer.id)
          this.map.removeSource(layer.id)
        }
        // Always add the background layer after the last mapbox layer
        if (!layerPresent && layer.active) {
          this.map.addLayer(layer, 'admin-0-boundary-bg')
        }
      })
    }
  }
}
</script>
