<template>
  <v-card flat height="100%" ref="results">
    <h2>
      Resultaten
    </h2>
    <result-page-immission
      v-if="$route.params.calculationType === 'immissietoets'"
      :input="input"
      :map="map"
      @update:correctResponseTime="correctResponseTime = $event"
      @set-error-message="$emit('set-error-message', $event)"
    />
    <result-page-temperature
      v-if="$route.params.calculationType === 'temperatuurlozing'"
      :input="input"
      :map="map"
      :backgroundLayers="backgroundLayers"
      @update:correctResponseTime="correctResponseTime = $event"
      @set-error-message="$emit('set-error-message', $event)"
    />
    <small> {{ correctResponseTime }}</small>
  </v-card>
</template>

<script>
import ResultPageImmission from './ResultPageImmission.vue'
import ResultPageTemperature from './ResultPageTemperature.vue'

export default {
  props: {
    input: {
      type: Object
    },
    map: {
      type: Object,
      required: true
    },
    backgroundLayers: {
      type: Array
    }
  },
  data () {
    return {
      correctResponseTime: ''
    }
  },
  components: {
    ResultPageImmission,
    ResultPageTemperature
  }
}
</script>

<style>
.tabs-items {
  height: auto;
}
</style>
