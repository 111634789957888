<template>
  <v-btn @click="printToPdf">
    Print to pdf
  </v-btn>
</template>

<script>
export default {
  name: 'PrintComponent',
  methods: {
    printToPdf () {
      window.print()
    }
  }
}
</script>
