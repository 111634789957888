<template>
  <div class="d-flex fill-height flex-column map-container pt-6">
    <v-row>
      <v-col cols="4" class="py-0">
        <v-combobox
          class="select-interactive"
          ref="selectInteractive"
          :items="interactiveLayers"
          label="Interactieve lagen"
          item-title="name"
          item-value="name"
          return-object
          v-model="interactiveLayersModel"
          density="compact"
          multiple
          height="52px"
          variant="underlined"
        ></v-combobox>
      </v-col>
      <v-col cols="4" class="py-0">
        <v-combobox
          class="select-informative"
          ref="selectInformative"
          :items="$store.state.informativeLayers"
          label="Informatieve lagen"
          item-title="name"
          item-value="name"
          return-object
          v-model="informativeLayersModel"
          density="compact"
          multiple
          height="52px"
          variant="underlined"
        ></v-combobox>
      </v-col>
      <v-col cols="4" class="py-0">
        <v-combobox
          class="select-background"
          ref="selectBackground"
          :items="backgroundLayers"
          label="Achtergrond lagen"
          item-title="name"
          item-value="id"
          return-object
          v-model="backgroundLayersModel"
          density="compact"
          height="52px"
          variant="underlined"
        ></v-combobox>
      </v-col>
    </v-row>
    <div class="map">
      <mapbox-map
        ref="mapboxmap"
        class="map"
        :access-token="mapboxToken"
        map-style="mapbox://styles/global-data-viewer/cla78u8z000gb15ph062nfrue"
        :preserveDrawingBuffer="true"
        @mb-created="setLocation"
      >
      <MapboxNavigationControl :visualizePitch="true" />
      <v-mapbox-background-layers :layers="backgroundLayers" />
      <v-mapbox-interactive-vector-layers :layers="interactiveLayers" v-model:tableHeaders="popupHeaders" v-model:tableItems="popupItems" v-model:popupLngLat="popupLngLat" />
      <v-mapbox-informative-vector-layers :layers="$store.state.informativeLayers" />
      <MapboxPopup v-if="popupHeaders" :lng-lat="[popupLngLat.lng, popupLngLat.lat]" ref="popup">
        <data-table :tableHeaders="popupHeaders" :tableItems="popupItems" @mb-close="popupHeaders=null"></data-table>
      </MapboxPopup>
    </mapbox-map>
    </div>
    <v-progress-linear
      v-if="!layersLoaded"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div v-if="!layersLoaded"> Kaartlagen worden geladen op de kaart </div>
    <v-alert min-height="42px" type="info" density="compact" variant="outlined" class="mt-3 mb-0 not-to-print">
      Rechter muisknop: Informatie over laag.
    </v-alert>
    <v-alert min-height="42px" type="info" density="compact" variant="outlined" class="mt-3 mb-0 not-to-print">
      Zoom in op de kaart tot detail niveau om een selectie te doen!
    </v-alert>
  </div>
</template>

<script>
import VMapboxBackgroundLayers from './VMapbox/VMapboxBackgroundLayers'
import VMapboxInteractiveVectorLayers from './VMapbox/VMapboxInteractiveVectorLayers'
import VMapboxInformativeVectorLayers from './VMapbox/VMapboxInformativeVectorLayers'
import DataTable from '@/components/DataTable'
import mapboxgl from 'mapbox-gl'
import { MapboxMap, MapboxNavigationControl, MapboxPopup } from '@studiometa/vue-mapbox-gl'
import _ from 'lodash'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      mapboxToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      backgroundLayers: require('../assets/backgroundLayers.json'),
      layersLoaded: false,
      popupHeaders: null,
      popupItems: [],
      popupLngLat: [0, 0]
    }
  },
  components: {
    MapboxMap,
    MapboxNavigationControl,
    MapboxPopup,
    DataTable,
    // Add different components for different layers
    VMapboxBackgroundLayers, // Background maps
    VMapboxInteractiveVectorLayers, // Vector layers with different user interactions
    VMapboxInformativeVectorLayers // Simple vector layers which only need displaying
  },
  watch: {
    '$refs.popup' () {
      this.popupHeaders = null
    },
    '$attrs.selectedMeasurement' (val, oldVal) {
      // When the selectedMeasurement has changed (selection in inputfields) adjust the
      // selection accordingly on the map.
      if (!this.map.getLayer('selection-measurements')) {
        this.addMeasurementSelection()
      }
      this.map.setFilter('selection-measurements', ['==', 'background_id', val])
    },
    '$attrs.selectedWaterBody' (val, oldVal) {
      // When the selectedWaterBody has changed (selection in inputfields) adjust the
      // selection accordingly on the map.
      this.map.setFilter('selection-waterbody', ['==', 'krw_waterbodies_id', val])
    },
    '$attrs.selectedLocation' (val, oldVal) {
      // When the selectedLocation has changed (selection in inputfields) adjust the
      // selection accordingly on the map.
      this.map.setFilter('selection-location', ['==', 'location_id', val])
    },
    '$route.params.calculationType' (val) {
      this.setActiveInteractiveLayers(val)
    }
  },
  mounted () {
    this.$emit('update-bg-layers', this.backgroundLayers)
    this.map = this.$refs.mapboxmap.map

    // Only do the first time, when switching between tabs (information page and calculation)
    // for example, you don't want to load in all the information again
    if (_.isEmpty(this.intakeInput)) {
      this.loadInteractiveLayers()
      this.loadInformativeLayers()
      this.loadCalcInput()
      this.loadWaterbodiesList()
      this.loadDrinkwaterIntakeList()
    }
    this.map.on('load', this.initializeData)
  },
  computed: {
    ...mapGetters(['intakeInput', 'interactiveLayers', 'customLocation']),
    backgroundLayersModel: {
      // Getter and setter for the selection of a background layer
      get () {
        return this.backgroundLayers.find(l => l.active)
      },
      set (val) {
        this.backgroundLayers.forEach(l => {
          if (l.id === val.id) {
            l.active = true
          } else {
            l.active = false
          }
        })
        this.$emit('update-bg-layers', this.backgroundLayers)
      }
    },
    interactiveLayersModel: {
      // Getter and setter for the selection of informative layers
      get () {
        return this.interactiveLayers.filter(l => l.active)
      },
      set (val) {
        let names = val
        if (typeof val[0] === 'object') {
          names = val.map(v => v.name)
        }
        this.$store.state.interactiveLayers.forEach(l => {
          if (names.includes(l.name)) {
            l.active = true
          } else {
            l.active = false
          }
        })
      }
    },
    informativeLayersModel: {
      // Getter and setter for the selection of informative layers
      get () {
        return this.$store.state.informativeLayers.filter(l => l.active)
      },
      set (val) {
        const names = val.map(v => v.name)
        this.$store.state.informativeLayers.forEach(l => {
          if (names.includes(l.name)) {
            l.active = true
          } else {
            l.active = false
          }
        })
      }
    }
  },
  methods: {
    ...mapActions([
      'loadInformativeLayers',
      'loadInteractiveLayers',
      'loadCalcInput',
      'loadWaterbodiesList',
      'loadDrinkwaterIntakeList',
      'loadRegionalWaterbodies'
    ]),
    ...mapMutations(['setSelectedIntake', 'setCustomLocation']),
    setLocation (map) {
      map.setZoom(7)
      map.setCenter([4.7, 52.2])
    },
    initializeData () {
      this.map.on('click', e => {
        if (this.map.getZoom() <= 10) {
          return
        }

        const features = this.map.queryRenderedFeatures(e.point)

        const featIds = features.map(feat => feat.layer.id)
        // Don't update the marker if you select a measurement location
        if (!featIds.includes('background-geojson')) {
          // For the first time clicked on the map, initialize the selection layers
          if (!this.map.getLayer('selection-location')) {
            this.initializeSelections()
          }
          if (this.marker) {
            this.marker.remove()
          }
          this.marker = new mapboxgl.Marker({ color: '#f9e11e' }).setLngLat(e.lngLat).addTo(this.map)
          this.getNearestIntake(e.lngLat)
          this.getRegionalWaterbodies(e.lngLat)
        }
        // Lighten background map so the selection is more visible
        this.map.setPaintProperty(
          `${this.backgroundLayersModel.id}`,
          'raster-opacity',
          0.5
        )
        this.setSelection(e)
      })

      // This is a check to see if all interactive layers are loaded on the map otherwise
      // show the progress indicating that layers are still loading.
      let sourcesLoaded = []
      const allSources = _.uniq(this.$store.state.interactiveLayers.map(layer => layer.source.id))
      this.map.on('sourcedata', (e) => {
        sourcesLoaded.push(e.sourceId)
        sourcesLoaded = _.uniq(sourcesLoaded)
        if (allSources.every(source => sourcesLoaded.includes(source))) {
          this.layersLoaded = true
        }
      })

      this.$emit('update:map', this.map)

      const calculationType = this.$route.params.calculationType
      this.setActiveInteractiveLayers(calculationType)
    },
    initializeSelections () {
      // Add layer for location selection
      this.map.addLayer({
        id: 'selection-location',
        type: 'line',
        source: 'locations-geojson',
        paint: {
          'line-color': '#4F5759',
          'line-width': 8
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        filter: ['==', 'location_id', '']
      })

      // Add layer for waterbody selection
      this.map.addLayer({
        id: 'selection-waterbody',
        type: 'line',
        source: 'waterbodies-geojson',
        paint: {
          'line-color': '#367DD9',
          'line-width': 8
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        filter: ['==', 'krw_waterbodies_id', '']
      })

      // Add layers for intake point selection
      this.map.addLayer({
        id: 'selection-intake',
        type: 'line',
        lineMetrics: true,
        source: {
          type: 'geojson',
          data: Object.assign({}, this.$store.state.emptyFeatureCollection)
        },
        paint: {
          'line-width': 4,
          'line-color': '#4F5759'
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        }
      })
      this.map.addLayer({
        id: 'selection-intake-point',
        type: 'circle',
        source: {
          type: 'geojson',
          data: Object.assign({}, this.$store.state.emptyFeatureCollection)
        },
        paint: {
          'circle-color': '#4F5759',
          'circle-stroke-color': '#4F5759',
          'circle-radius': 6
        }
      })
    },
    addMeasurementSelection () {
      // Add layer for measurement point selection
      this.map.addLayer({
        id: 'selection-measurements',
        type: 'circle',
        source: 'background-geojson',
        paint: {
          'circle-color': 'white',
          'circle-radius': 10,
          'circle-stroke-color': '#4F5759',
          'circle-stroke-width': 4
        }
      }, 'background-geojson')
    },
    setSelection (e) {
      // The background-geojson can be changed any time the user changes a substance.
      // But when initializing the website, this layer is no yet added to the map.
      // Check if available before setting seleciton
      if (this.map.getLayer('background-geojson')) {
        const backgroundFeatures = this.map.queryRenderedFeatures(e.point, { layers: ['background-geojson'] })
        if (backgroundFeatures.length > 0) {
          this.$emit('update:selectedMeasurement', backgroundFeatures[0].properties.background_id)
          return
        }
      }

      let features = this.map.queryRenderedFeatures(e.point, { layers: ['locations'] })
      const selectedLocation = _.get(features, '[0].properties.location_id', '')
      if (selectedLocation === '') {
        if (this.customLocation === false) {
          this.loadCalcInput()
          this.$emit('reset-input')
        }
        this.setCustomLocation(true)
      } else {
        if (this.customLocation === true) {
          this.loadCalcInput()
          this.$emit('reset-input')
        }
        this.setCustomLocation(false)
      }
      this.$emit('update:location', e.lngLat)
      this.$emit('update:selectedLocation', selectedLocation)

      features = this.map.queryRenderedFeatures(e.point, { layers: ['waterbodies-geojson'] })
      this.$emit('update:selectedWaterBody', _.get(features, '[0].properties.krw_waterbodies_id', ''))
    },
    getNearestIntake (lngLat) {
      // Get nearest intake segments
      fetch(`${process.env.VUE_APP_SERVER_URL}/drinkwater_line_segments_geojson/?longitude=${lngLat.lng}&latitude=${lngLat.lat}`)
        .then(res => {
          return res.json()
        })
        .then(data => {
          // show result on map and set the retrieved drinkwater_segment_id in store
          const loc = _.get(data, 'features[0].properties.drinkwater_segment_id')
          this.map.getSource('selection-intake').setData(data)
          this.map.getSource('selection-intake-point').setData(data)
          this.$store.state.intakeInput.segment_id.data = loc
          this.updateBackgroundTemperature(loc)
        })
    },
    updateBackgroundTemperature (loc) {
      // Get background
      fetch(`${process.env.VUE_APP_SERVER_URL}/drinkwater_line_segments/${loc}`)
        .then(res => {
          return res.json()
        })
        .then(data => {
          // Update the background temperature in the store.
          const watertype = this.$store.state.calcInput.watertype.data
          const inputs = ['intakeInput', 'calcInput', 'advancedCalcInput.water.properties', 'temperatureCalcInput.water.properties']
          inputs.forEach(inputFields => {
            Object.entries(_.get(this.$store.state, inputFields, [])).forEach(input => {
              const nwm = _.get(input[1], 'properties.nwm_available')
              if (nwm && _.get(nwm, 'watertypes', []).includes(watertype)) {
                const newVal = parseFloat(data[input[0]]).toFixed(3)
                _.set(this.$store.state, `${inputFields}.${input[0]}.data`, newVal)
                _.set(this.$store.state, `${inputFields}.${input[0]}.default`, newVal)
                _.set(this.$store.state, `${inputFields}.${input[0]}.nwmdata`, newVal)
              }
            })
          })

          const temp = parseFloat(data.temperature)
          _.set(this.$store.state, 'intakeInput.w_temperature_bottom.data', temp)
          _.set(this.$store.state, 'intakeInput.w_temperature_surface.data', temp)
        })
    },
    // based on the route/calculation type preselect interactive layers
    setActiveInteractiveLayers (calculationType) {
      this.interactiveLayersModel = calculationType === 'temperatuurlozing'
        ? ['Drinkwater segmenten']
        : ['Locaties', 'Waterlichamen']
    },
    getRegionalWaterbodies (lngLat) {
      if (this.$route.params.calculationType) { this.loadRegionalWaterbodies(lngLat) }
    }
  }
}
</script>

<style lang="css">
.mapboxgl-map, .map {
  width: 100%;
  height: 100%;
}

.mapboxgl-popup-content {
  width: fit-content;
}
</style>
