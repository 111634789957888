<template>
  <v-card class="pa-3 ma-3" outlined>
    <h2>
      Informatie
    </h2>
    <div class="information" md="12">
      <div class="information-markdown" v-html="content" />
    </div>
  </v-card>
</template>

<script>
import content from '@/assets/content/information.md'
export default {
  name: 'InformationView',
  data () {
    return {
      content
    }
  }
}
</script>

<style scoped>
.information {
  width: 100%;
}
</style>
