<template>
  <v-card class="map-legend" flat max-height="300">
    <div class="legend-content">
      <v-card-title class="justify-center legend-title" v-if="legendTitle">
        {{ legendTitle }}
      </v-card-title>
      <div>
        <div :style="legendColors" class="bar"></div>
        <div class="bartext">{{ legendText }}</div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  // SAMPLE DATA
  props: {
    legendColors: {
      type: String,
      /* eslint max-len: ["error", { "code": 385, "tabWidth": 4 }] */
      default:
        'background: linear-gradient(to right, #CDCDCD 0%,  #CDCDCD 8.3%, #FF2B00 8.3%, #FF2B00 16.6%, #FF5D00 16.6%, #FF5D00 25%, #FF8C00 25%, #FF8C00 33.2%, #FFBB00 33.3%, #FFBB00 41.6%, #FFEA00 41.6%, #FFEA00 50%, #E9F500 50%, #E9F500 58.3%, #BFE600 58.3%, #BFE600 66.6%, #96D600 66.6%, #96D600 75%, #74C700 75%, #74C700 83.3%, #56B800 83.3%, #56B800 91.6%, #37A800 91.6%) '
    },
    legendText: {
      type: String,
      default: '0 3 6 9 12'
    },
    legendTitle: {
      type: String
    }
  }
}
</script>
<style>
.bar {
  width: 100%;
  height: 10px;
}
.bartext {
  width: 100%;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}
</style>
