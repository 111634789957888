<template>
  <div class="information pb-9">
    <h2>Informatie temperatuurlozing</h2>
    <p class="information-text">
    Hier kan een berekening voor koudwaterlozing worden uitgevoerd.<br>
    Uitgangspunt is dat het te lozen debiet bovenstrooms uit het ontvangende water is onttrokken,
    waardoor het netto debiet bij het lozingspunt minder is dan de opgegeven maatgevende afvoer.<br>
    De temperatuur van het onvangende water die na het kiezen van een locatie wordt getoond is niet gebaseerd op metingen.
    Er kan bij de invoer van de effluentgegevens worden gekozen tussen het opgeven
    van een absolute temperatuur of temperatuurverschil van de lozing met de omgeving.
    Het is belangrijk dat het temperatuurverschil tussen lozing en ontvangende water goed is aangegeven
    omdat het dichtheidverschil een belangrijke factor kan zijn in de verspreiding van de lozingspluim.<br>
    Het is van belang de vooraf ingevulde waarden te controleren. Aangepaste velden worden geel getoond.
  </p>
  </div>
</template>

<style>
.information-text {
  overflow-y: auto;
}

.information, .information-text {
  height: 100%;
}
</style>
