<script>
import mapboxgl from 'mapbox-gl'
import _ from 'lodash'

export default {
  name: 'VMapboxInteractiveVectorLayers',
  render () {
    return null
  },
  props: {
    layers: {
      type: Array,
      required: true,
      default: () => []
    },
    tableHeaders: {
      type: Array
    },
    tableItems: {
      type: Array
    },
    popupLngLat: {
      type: Array
    }
  },
  watch: {
    layers: {
      handler () {
        this.setVectorState()
      },
      deep: true
    }
  },
  data () {
    return {
      map: null,
      popup: null
    }
  },
  mounted () {
    this.map = this.$parent.map
    this.setVectorState()
  },
  methods: {
    setVectorState () {
      // Toggle the visibility of the layers in the layer list. When the layer
      // is not present on the map add to the map.
      if (!this.map) {
        return
      }

      this.layers.forEach(layer => {
        let vis = 'none'
        if (layer.active) {
          vis = 'visible'
        }

        // Check if the source is added to the map, otherwise add this
        if (_.get(layer, 'source') && !this.map.getSource(_.get(layer, 'source.id'))) {
          this.map.addSource(_.get(layer, 'source.id'), _.get(layer, 'source.data'))
        }
        // If there is an update in the background-geojson, update its source.
        if (layer.name === 'Meetpunten') {
          this.map.getSource(layer.source.id).setData(_.get(layer, 'source.data.data'))
        }

        layer.layers.forEach(childLayer => {
          // Check for each layer in this group whether the layer was added to the map
          // otherwise add the layers to the map
          if (!this.map.getLayer(childLayer.id)) {
            this.map.addLayer(childLayer)
            this.map.on('contextmenu', childLayer.id, this.showInfoPopup)
            this.map.on('mouseover', childLayer.id, this.mouseoverEffect)
            this.map.on('mouseleave', childLayer.id, this.mouseleaveEffect)
          }
          // Leave locations layer always open
          if (childLayer.id === 'locations') {
            let opacity = 0
            if (layer.active) {
              opacity = 0.2
            }
            this.map.setPaintProperty('locations', 'fill-opacity', opacity)
          } else {
            this.map.setLayoutProperty(childLayer.id, 'visibility', vis)
          }
        })
      })
    },
    mouseoverEffect () {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    mouseleaveEffect () {
      this.map.getCanvas().style.cursor = ''
    },
    showInfoPopup (e) {
      this.$emit('update:popupLngLat', e.lngLat)

      // Get all poroperties per feature and display that in  a popup
      this.popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true
      })
      const f = e.features[0]

      this.$emit('update:tableHeaders', [
        {
          text: 'Metadata',
          align: 'left',
          sortable: false,
          value: 'name',
          class: 'primary'
        },
        {
          style: 'font-color: blue',
          align: 'left',
          sortable: false,
          value: 'value',
          class: 'primary'
        }
      ])

      const tableItems = []

      Object.entries(f.properties).forEach(val => {
        tableItems.push({
          value: val[1],
          name: val[0]
        })
      })

      this.$emit('update:tableItems', tableItems)
    }
  }
}
</script>
